import React, {HTMLAttributes, useMemo} from 'react';
import {ReactSVG} from 'react-svg';
import classNames from 'classnames';
import Tooltip from '../../atoms/Tooltip';
import './TableRow.scss';

export interface TableRowInterface {
  content: JSX.Element | string,
  contentIcon?: string,
  index: number,
  label: JSX.Element | string,
  helpText?: string;
  helpLink?: string;
}

const TableRow = (props: TableRowInterface & HTMLAttributes<HTMLDivElement>) => {
  const {label, content, contentIcon, index, helpText, helpLink, ...divAttr} = props;

  const rowClasses = useMemo(() => classNames({
    'table-row': true,
    'gray-bg': ((index % 2) !== 0),
  }), [index]);

  return (
    <div { ...divAttr } className={rowClasses}>
      <span className="table-row__content">
        {contentIcon &&
          <ReactSVG src={contentIcon} className="table-row__content-icon"/>}
        {content}
      </span>
      <div className="table-row__wrapper">
        <span className="table-row__label">{label}</span>
        <span className="table-row__help">
          {(helpText || helpLink) && (
            <Tooltip
              msg={
                <p>
                  {helpText}
                  {helpLink && <a href={helpLink} target="_blank"> Learn more.</a>}
                </p>
              }
              position="right"
            >
              <span className="table-row--help-icon"></span>
            </Tooltip>
          )}
        </span>
      </div>
    </div>
  );
}

export default TableRow;
