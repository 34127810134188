import React from 'react';

import { Value } from '../../types';
import RadioButton from '../../../../atoms/RadioButton';

import OptionWithImage from './OptionWithImage';
import './Option.scss';

const Option = (option: Value) => {
  const optionLabel = option.label || option.title;
  const helpText = option.helpText;

  const renderImage = () => (
    <div className="schematic-wrapper">
      <OptionWithImage option={option} />
    </div>
  );

  const renderLabel = () => (
    <div className="label-wrapper">
      <div className="label-value">{optionLabel}</div>
      <div className="label-help-text-wrapper">
        <div className="label-help-text">{helpText}</div>
      </div>
    </div>
  );

  return (
    <div className={option.image && 'option-with-image'} key={`radio-${option.code}`}>
      <RadioButton value={option.code}>{option.image ? renderImage() : renderLabel()}</RadioButton>
    </div>
  );
};

export default Option;
